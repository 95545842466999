@import '../../styles/variables.scss';

.front-page {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @supports(display: grid) {
    display: grid;
    justify-items: stretch;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: $tablet) {    
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: $desktop) {    
      grid-template-columns: repeat(4, 1fr);
    }
  }
}