@import '../../styles/variables.scss';

.profile-picture {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;

  @media screen and (min-width: $tablet) {
    width: 50%;
  }

  @media screen and (min-width: $desktop) {
    width: 25%;
  }

  @supports(display: grid) {
      @media screen and (min-width: $tablet) {
        width: 100%;
      }

      @media screen and (min-width: $desktop) {
        width: 100%;
      }
    }
  
  &__overlay {
    position: absolute;
    background: rgba(black, .6);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 100%);
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2em .5em .5em;
  }

  &__name {
    color: white;
    font-weight: lighter;
    text-align: center;
    display: block;
  }

  img {
    opacity: .85;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:hover {
      opacity: 1;
    }
  }
}