@import '../../styles/colors.scss';

.breadcrumbs {
  padding: 1em 0;
  background-color: $dark-green;
  border-bottom: .2rem solid darken($dark-green, 10%);

  &__nav {

  }

  &__link {
    padding: .5em;
    color: white;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--active {
      color: white;
      opacity: .7;
    }

    &--divider {
      padding: .5em;
      color: white;
      opacity: .7;
    }
  }
}