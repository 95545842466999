@import '../../styles/variables';

.person-page {
  padding-bottom: 5em;
  
  &__title {
    
  }

  &__profile-picture {
    float: left;
    width: 100%;
    padding: 0;
    
    @media screen and (min-width: $tablet) {
      padding: 0 2em 2em 0;
      width: 50%
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__paragraph {
    
  }

  .image-gallery-content {
    .image-gallery-image {
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &.fullscreen {
      .image-gallery-image {
        height: 100vh;
        width: 100vw;
      }
    }

    &:not(.fullscreen) {
      .image-gallery-image {
        height: 60rem;
      }
    }
  }
}