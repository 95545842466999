@import '../../styles/variables';

.section {
  max-width: 96rem;
  margin: 0 auto;
  padding: 0 1em;

  &--narrow {
    padding-right: 0;
    padding-left: 0;

    @media screen and (min-width: $tablet) {
      padding-right: 10%;
      padding-left: 10%;
    }
  }
}