html {
  font-size: 62.5%;
  
  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    min-height: 100vh;

    .app {
      display: flex;
      flex-direction: column;

      .page-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;

        @supports(display: grid) {
          display: grid;
          grid-template-rows: auto 1fr auto;
        }

        > [class*="-page"] {
          flex-grow: 1;
        }
      }
    }
  }
}

