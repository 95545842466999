@import '../../styles/variables.scss';

.signature {
  &-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 2em;
    margin: 2em 0;
  }

  text-align: center;
  
}