@import '../../styles/colors.scss';

.header {
  background-color: $dark-red;
  border-bottom: .2rem solid darken($dark-red, 10%);

  &__container {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 2em;
    padding: .5em 0;
    color: white;
  }

  &__link {
    color: white;
    text-decoration: none;
  }

  &__subtitle {
    font-size: .75em;
    padding: 0 .5em;
    font-weight: lighter;
  }

  &__nav {

  }

  &__link {

  }
}